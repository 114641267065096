import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/layout'
import { Container } from '@chakra-ui/react'
import { Router, Switch } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import { createBrowserHistory } from 'history';
import { getAuth } from '@firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import Header from './components/Header'
import Auth from './pages/Auth'
import AvailabilityRequest from './pages/AvailabilityRequests'
import Home from './pages/Home'
import Deploy from './pages/Deploy'
import { isAuthenticated } from './utils/auth'

const history = createBrowserHistory();

const requireLogin = (to, from, next) => {
  if (to.meta.auth) {
    if (isAuthenticated()) { next(); return }
    next.redirect('/auth')
    return
  } else if (to.match.path === '/auth' && isAuthenticated()) {
    next.redirect('/')
    return
  } else {
    next()
  }
}

const buildRoute = (Component, path, props = {}, LayoutComponent = AdminLayout, isGuarded = true) => ({
  Component: () => (
    <LayoutComponent>
      <Component {...props} />
    </LayoutComponent>
  ),
  path,
  props,
  isGuarded
})

const DefaultLayout = ({ children }) => (
  <Box display="flex" h="100vh" placeContent="center">
    <Box placeSelf="center">
      { children }
    </Box>
  </Box>
)

const AdminLayout = ({ children, loading }) => (
  <Box>
    <Header />
    <Container maxW="container.lg">
      <Box pt={15}>
        { children }
      </Box>
    </Container>
  </Box>
)

const routes = [
  buildRoute(AvailabilityRequest, '/inquiries'),
  buildRoute(Home, '/'),
  buildRoute(Deploy, '/deploy'),
  buildRoute(Auth, '/auth', {}, DefaultLayout, false),
]

const AppRouter = () => {
  const auth = getAuth()
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (!loading && user === null) {
      localStorage.setItem('IS_LOGGED_IN', false)
      history.push('/auth')
    } else if (!loading && user !== null) {
      localStorage.setItem('IS_LOGGED_IN', true)
      if (history?.location?.pathname === '/auth') {
        history.push('/')
      }
    }
  }, [user, loading])

  return (
    <Router history={history}>
      <GuardProvider guards={[ requireLogin ]} loading={() => 'loading'} error={() => 'route not found'}>
        <Switch>
          {
            routes.map(({ path, Component, props = {}, isGuarded }) => (
              <GuardedRoute path={path} key={path} exact meta={isGuarded ? { auth: true } : {}}>
                <>
                  <Component {...props} loading={loading} />
                </>
              </GuardedRoute>
            ))
          }
        </Switch>
      </GuardProvider>
    </Router>
  )
}

export default AppRouter
