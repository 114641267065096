import './App.css'
import Header from './components/Header'
import AppRouter from './Router'

const App = () => (
  <div className="App">
    <AppRouter>
      <Header />
    </AppRouter>
  </div>
)

export default App;
