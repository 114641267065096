import React from 'react'
import { Box, Text, Link as StyledLink, Heading } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <Box>
      <Heading as="h1" size="md" mt={3}>Essentia Blooms - Portal</Heading>
      <Text as="p" my={2} mb={10}>This is your website's administration panel. Click the tile below for what you would like to manage.</Text>
      <Box my={3}>
        <StyledLink to="/inquiries" color="pink.400" as={Link}>View All Inquiries</StyledLink>
      </Box>
      <Box my={3}>
        <StyledLink to="/deploy" color="pink.400" as={Link}>Deploy Your Site</StyledLink>
      </Box>
    </Box>
  )
}

export default Home