import React from 'react'
import { Alert, Box, Button, Text,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Link
} from '@chakra-ui/react'
import Breadcrumbs from '../components/Breadcrumbs'

const estimatedBuildTime = 120

const Deploy = () => {
  const [showError, setShowError] = React.useState(false)
  const [showCountdown, setShowCountdown] = React.useState(false)
  const [countdown, setCountdown] = React.useState(estimatedBuildTime)
  const [countdownComplete, setCountdownComplete] = React.useState(false)
  const [countdownTimer, setCountdownTimer] = React.useState(null)

  const triggerDeploy = async () => {
    setShowError(false)
    try {
      await fetch('https://api.netlify.com/build_hooks/63ee3028c3171c06a6904b17', { method: 'POST' })
      setShowCountdown(true)
      startCountdown()
    } catch (e) {
      setShowError(true)
    }
  }

  const startCountdown = () => {
    setCountdownComplete(false)
    setCountdown(estimatedBuildTime)
    let count = estimatedBuildTime
    const timer = setInterval(() => {
      count -= 1
      setCountdown(count)
    }, 1000)
    setCountdownTimer(timer)
  }

  React.useEffect(() => {
    if (countdown < 1) {
      clearInterval(countdownTimer)
    }
  }, [countdown, countdownTimer])

  React.useEffect(() => {
    if (countdown === 0) {
      setShowCountdown(false)
      setCountdownTimer(null)
      setCountdownComplete(true)
    }
  }, [countdown])

  return (
    <Box>
      <Breadcrumbs pages={[{name: 'Home', path: '/'}, {name: 'Deploy'}]} />
      <Text fontSize="xl" mb={4}>Deploy your site</Text>
      <Text as="p" mb={6}>By clicking the button below, you will trigger your website to rebuild itself and deploy the latest active products in Shopify. Once you click the button it takes ~ 2 minutes to update.</Text>
      { showError && (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Deploy failed</AlertTitle>
          <AlertDescription>Something doesn't appear to be working, please contact Collier</AlertDescription>
        </Alert>
      ) }
      { countdownComplete && (
        <Text fontSize="xl" mb={6}>Your site should be deployed now: <Link color="purple" href="https://www.essentiablooms.com" target="_blank">www.essentiablooms.com</Link></Text>
      )}
      { showCountdown ? (
        <>
          <Spinner />
          <Text fontSize="lg">Your site should be ready in approximately:</Text>
          <Text fontSize="4xl">
            { countdown > 60
              ? (
                <>{Math.floor(countdown / 60)} <Text as="span">minute{Math.floor(countdown / 60) > 1 && 's'}</Text> { countdown % 60 > 0 && (<>{countdown % 60} <Text as="span">seconds</Text></>)}</>
              ): (
                <>{countdown} <Text as="span">seconds</Text></>
              )}
            </Text>
        </>
      ) : (
        <Button variant="solid" size="lg" colorScheme="red" onClick={triggerDeploy}>DEPLOY ESSENTIABLOOMS.COM</Button>
      )}
    </Box>
  )
}

export default Deploy