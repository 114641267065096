import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image,
  useDisclosure,
  Text,
  Container
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import defaultProfilePic from '../assets/default_prof_pic.jpg'

const headerHeight = [85, 90, 100, 110]

export const SideMenu = () => {
  const auth = getAuth()
  const [user] = useAuthState(auth)
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const logout = () => {
    auth.signOut()
    history.replace('/auth')
  }

  return (
    <>
      <Box height={headerHeight} w={50} display="flex" justifyContent="center" alignItems="center" color="pink.400" fontWeight={600}>
        { user !== null && (
          <>
            <Image className="profile" src={user?.photoURL} alt="profile" onClick={onOpen} ref={btnRef} />
          </>
        )}
        { user === null && (
          <Image className="profile" src={defaultProfilePic} alt="profile" onClick={onOpen} ref={btnRef} />
        )}
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader style={{ borderBottom: '1px solid #999' }}>
            { user !== null && (
              <Image className="profile" src={user?.photoURL} alt="profile" onClick={onOpen} ref={btnRef} />
            )}
            { user === null && (
              <Image className="profile" src={defaultProfilePic} alt="profile" onClick={onOpen} ref={btnRef} />
            )}
            <Text mt={2} fontSize="0.9em">{user?.displayName}</Text>
          </DrawerHeader>
          <DrawerBody>
            <DrawerLink text="Home" path="/" onClick={onClose} />
            <DrawerLink text="Inquiries" path="/inquiries" onClick={onClose} />
            <DrawerLink text="Deploy Website" path="/deploy" onClick={onClose} />
            <Box my={3} py={2}>
              <a href="/" onClick={e => { e.preventDefault(); logout(); onClose(); }}>
                <Text color="pink.400" fontWeight={600}>
                  Logout
                </Text>
              </a>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const DrawerLink = ({ path, text, onClick }) => {
  const history = useHistory()

  return (
    <Box my={3} py={2}>
      <a href="/" onClick={e => { e.preventDefault(); history.push(path); onClick();}}>
        <Text color="pink.400" fontWeight={600}>
          {text}
        </Text>
      </a>
    </Box>
  )
}

export const Header = ({ color = 'gray.700', links, ...props }) => (
  <Box className="header header-white" zIndex={1100} minHeight={headerHeight} boxShadow="0 2px 15px rgb(0, 0, 0, 0.1)">
    <Container maxW="container.lg">
      <SideMenu links={links} />
    </Container>
  </Box>
)

export default Header;
