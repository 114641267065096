import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({ pages }) => {
  return (
    <Flex alignItems="center" mb={4}>
      <Breadcrumb separator="/">
        {
          pages.map(({ name, path }, index) => {
            const isCurrentPage = index >= pages.length - 1
            return (
              <BreadcrumbItem isCurrentPage={isCurrentPage} key={`${name}-${index}`}>
                <BreadcrumbLink aria-label={`Go to ${name} page`} as={Link} to={ path ?? '#' } _disabled={isCurrentPage}>{name}</BreadcrumbLink>
              </BreadcrumbItem>
            )
          })
        }
      </Breadcrumb>
    </Flex>
  )
}

export default Breadcrumbs
