import React, { useEffect, useState } from 'react'
import { Timestamp } from '@firebase/firestore'
import { DateTime } from 'luxon'
import { Box, Divider, Link, Text } from '@chakra-ui/layout'

const convertFirestoreTimestamp = ({ seconds, nanoseconds }) => {
  return new Timestamp(seconds, nanoseconds)
}

const AvailabilityRequestCard = ({
  firstName,
  lastName,
  email,
  eventType,
  eventDate,
  comments,
  created_at,
  selected = false,
  showComments = false,
  onClick = () => null,
}) => {
  const submissionDate = convertFirestoreTimestamp(created_at)
  const submissionTimestamp = DateTime.fromJSDate(submissionDate.toDate()).toLocaleString(DateTime.DATE_FULL)
  const timestamp = convertFirestoreTimestamp(eventDate)
  const eventTimestamp = DateTime.fromJSDate(timestamp.toDate()).toLocaleString(DateTime.DATE_MED)

  const [isTest, setIsTest] = useState(false)

  useEffect(() => {
    if (firstName.toLowerCase() === 'collier' && lastName.toLowerCase() === 'devlin' && email === 'jocodev@gmail.com' && comments.indexOf('test')) {
      setIsTest(true)
    }
  }, [firstName, lastName, email, comments])

  return (
    <Box className={`card ${selected ? 'card-selected' : ''}`} my={4} style={{ opacity: isTest ? 0.5 : 1 }}>
      <Box className="card-top" onClick={onClick}>
        { isTest && <Text fontSize="md">INTERNAL TESTING</Text> }
        <Text fontSize="md">{eventType.toUpperCase()} on {eventTimestamp || '---'}</Text>
        <Text fontSize="sm">{firstName} {lastName}</Text>
        <Text fontSize="sm"><Link href={`mailto:${email}`}>{email}</Link></Text>
        <Text fontSize="xs" mt={2} color="gray">{submissionTimestamp || '---'}</Text>
      </Box>
      { showComments && (
        <Box className="card-bottom">
          <Divider my={3} />
          <Text fontSize="sm">{comments}</Text>
        </Box>
      )}
    </Box>
  )
}

export default AvailabilityRequestCard