import { Button } from '@chakra-ui/button'
import { Heading, Box, Text } from '@chakra-ui/layout'
import { Progress } from '@chakra-ui/progress'
import { collection, query, orderBy, startAfter, getDocs, getFirestore, limit } from '@firebase/firestore'
import React, { useEffect, useState } from 'react'
import AvailabilityRequestCard from '../components/AvailabilityRequestCard'
import Breadcrumbs from '../components/Breadcrumbs'

const AvailabilityRequest = () => {
  const db = getFirestore()
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [lastVisible, setLastVisible] = useState(null)
  const [pageCount, setPageCount] = useState(1)
  const [maxPages, setMaxPages] = useState(null)

  const [selectedIndex, setSelectedIndex] = useState(null)

  const queryNextPage = async () => {
    const results = []
    const requestsRef = collection(db, "availabilityRequests")
    const q = query(requestsRef, orderBy('created_at', 'desc'), startAfter(lastVisible), limit(10))
    const querySnap = await getDocs(q)
    if (querySnap.docs.length === 0) {
      setMaxPages(pageCount)
    } else {
      setPageCount(pageCount + 1)
      setLastVisible(querySnap.docs[querySnap.docs.length - 1])
      querySnap.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data()
        })
      });
  
      setLoading(false)
      setResults(results)
    }
  }

  const startOverQuery = async () => {
    const results = []
    const requestsRef = collection(db, "availabilityRequests")
    const q = query(requestsRef, orderBy('created_at', 'desc'), limit(10))
    const querySnap = await getDocs(q)
    setPageCount(1)
    setLastVisible(querySnap.docs[querySnap.docs.length - 1])
    querySnap.forEach((doc) => {
      results.push({
        id: doc.id,
        ...doc.data()
      })
    });

    setLoading(false)
    setResults(results)
  }

  useEffect(() => {
    const db = getFirestore()
    const queryRecords = async () => {
      const results = []
      const requestsRef = collection(db, "availabilityRequests")
      const q = query(requestsRef, orderBy('created_at', 'desc'), limit(10))
      const querySnap = await getDocs(q)
      setPageCount(1)
      setLastVisible(querySnap.docs[querySnap.docs.length - 1])
      querySnap.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data()
        })
      });
  
      setLoading(false)
      setResults(results)
    }
    
    setLoading(true)
    queryRecords()
  }, [])

  useEffect(() => {
    if (results.length === 0) {
      setSelectedIndex(null)
    }
  }, [results])

  return (
    <div>
      <Breadcrumbs pages={[{name: 'Home', path: '/'}, {name: 'Inquiries'}]} />
      <Heading as="h1" size="md" mt={3}>Inquiries</Heading>
      <Text as="p" my={2} mb={10}>
        Below are all of the inquiries that come through your website. Clicking on these will show additional information and the comments that they leave. For now, no images are stored, but they should have been sent to you through email at the time the form was submitted.
      </Text>
      { loading && <Progress size="xs" isIndeterminate /> }
      { results.map((result, i) => (
        <AvailabilityRequestCard key={`${result.lastName}-${i}`} selected={selectedIndex === i} showComments={selectedIndex === i} {...result} onClick={() => { if (selectedIndex === i) { setSelectedIndex(null) } else { setSelectedIndex(i) } }} />
      ))}
      {
        !loading && results?.length > 0 && (
          <Box my={5} textAlign="center">
            <Button colorScheme="teal" onClick={startOverQuery}>Start Over</Button>
            <Button colorScheme="teal" onClick={queryNextPage} disabled={maxPages !== null && maxPages === pageCount} ml={4}>Next</Button>
            <Text mt={3}>Page {pageCount}</Text>
          </Box>
        )
      }
    </div>
  )
}

export default AvailabilityRequest
