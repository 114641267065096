import React from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { collection, getDocs, query, where, getFirestore } from '@firebase/firestore'
import './Auth.css'
import { Button } from '@chakra-ui/button'
import { Box, Text } from '@chakra-ui/layout'

const Auth = () => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  const [user] = useAuthState(auth)

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider)
      const db = getFirestore()
      const docRef = query(collection(db, "users"), where("email", "==", result.user.email))
      const docSnap = await getDocs(docRef)

      if (docSnap.docs.length === 0) {
        auth.signOut()
      }
    } catch(error) {
      console.log(error)
    }
  }

  const logout = () => {
    auth.signOut()
  }

  return (
    <Box w={300} py={10} backgroundColor="white" border="1px solid #f9f9f9" borderRadius="1em" textAlign="center">
      <Text fontSize="xl" fontWeight="bold">Essentia Blooms - Portal</Text>
      {
        user
          ? (
            <div>
              <img className="profile" src={user?.photoURL} alt="profile" />
              <p>Logged in as: {user?.displayName}</p>
              <button onClick={logout}>Logout</button>
            </div>
          )
          : <Button mt={10} colorScheme="teal" onClick={handleLogin}>Login with Google</Button>
      }
    </Box>
  )
}

export default Auth
